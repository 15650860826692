/* Tailwind directives  */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
:root {
  @font-face {
    font-family: 'FordMotion';
    src: url('/assets/fonts/ford/FordMotion.ttf') format('truetype');
  }

  font-family: 'FordMotion', Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color: #1F3047;
  background-color: #FAFAFB;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  min-width: 320px;
  height: 100vh;
  min-height: 100vh;
}

.marked > ul, ol {
  list-style: auto !important;
  padding: 0 0 0 40px;
}
